import axios from 'axios'
import {UserDbModel} from '../models/UserModel'
import {API_URL} from './constants'
import crypto from 'crypto';
import axiosRetry from 'axios-retry';

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/whoAmI`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const USER_URL = `${API_URL}/user/`
export const USER_MYSELF_URL = `${API_URL}/user_myself/`
export const PATCH_USER_URL = `${API_URL}/patch_user/`
export const DELETE_USER_URL = `${API_URL}/delete_user/`
export const USER_ONBOARDING_URL = `${API_URL}/user-onboarding`
export const API_KEY_URL = `${API_URL}/api-keys`
export const DELETE_API_KEY_URL = `${API_URL}/delete-api-keys/`
export const USERS_URL = `${API_URL}/users`
export const VIEW_MY_TESTERS_URL = `${API_URL}/view-my-testers`
export const USERS_BY_ORG_URL = `${API_URL}/userByOrg`;
const GENERATE_LOGIN_URL = `${API_URL}/generateLoginUrl`;
const GENERATE_LOGOUT_URL = `${API_URL}/generateLogoutUrl`;
const HANDSHAKE_URL = `${API_URL}/handshake`;
const IS_TOKEN_VALID_URL = `${API_URL}/isTokenValid`;
const LOGOUTALL_URL = `${API_URL}/logoutAll`;
const USER_ONBOARDING_DOC_URL = `${API_URL}/user/downloadOnboarding/`;
const XLS_TEST_REPORT_URL = `${API_URL}/test-runs/download_report/`;
const XLS_WEBSITES_REPORT_URL = `${API_URL}/websites_download_report/`;

const hashPass = (password: string) => crypto.createHash('sha256').update(password).digest('hex');

const key = crypto.createHash('md5').update(process.env.REACT_APP_ENCRYPT_SECRET_KEY || '').digest("hex");
 
function decrypt(text: any) {
    if (!text.iv) {
      return text;
    }
    let iv = Buffer.from(text.iv, 'hex');
    let encryptedText = Buffer.from(text.encryptedData, 'hex');
    let decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(key), iv);
    let decrypted = decipher.update(encryptedText);
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    return JSON.parse(decrypted.toString());
}

async function decrypter(promiseRes: any) {
  const {data} = await promiseRes;
  return {data: decrypt(data)};
}

export function generateLoginUrl() {
  return axios.post(GENERATE_LOGIN_URL);
}

export function generateLogoutUrl(userName: string, sessionId: string) {
  return axios.post(GENERATE_LOGOUT_URL, {userName, sessionId});
}

export function logoutAll(userName: string, sessionId: string, browserId: string, localTokenId: string) {
  return axios.post(LOGOUTALL_URL, {userName, sessionId, browserId, localTokenId});
}

export function handshake(handshakeId: string, email?: string, password?: string) {
  return decrypter(axios.post(HANDSHAKE_URL, {handshakeId, email, password}));
}

export function isTokenValid(userName: string, sessionId: string, browserId: string, localTokenId: string) {
  const retryableAxios = axios.create();
  axiosRetry(retryableAxios, {retries: 3});
  return retryableAxios.post(IS_TOKEN_VALID_URL, {userName, sessionId, browserId, localTokenId});
}

export function createUser(name: string, email: string, role: string, departmentIds: number[]) {
  const parichay = {fullName: name, givenName: name};
  return decrypter(axios.post(USER_URL, {email, role, parichay, status: 'ACTIVE', departmentIds}));
}

export function createOnboardingRequest(email: string, parichay: any, file: any) {
  const config = {
      headers: {
          'content-type': 'multipart/form-data'
      }
  };
  const formData = new FormData();
  formData.append('file', file)
  formData.append('email', email);
  formData.append('parichay', JSON.stringify(parichay));
  return axios.post(USER_ONBOARDING_URL, formData, config);
}

export function getOnboardingRequest() {
  return axios.get(USER_ONBOARDING_URL);
}

export function getApiKeys() {
  return axios.get(API_KEY_URL);
}

export function deleteApiKey(id: any) {
  return axios.post(DELETE_API_KEY_URL + id);
}

export function createApiKey(serviceName: string) {
  return axios.post(API_KEY_URL, {serviceName});
}

export function getDownloadOnboardingDoc(id: any) {
  return axios.get(USER_ONBOARDING_DOC_URL + id, {responseType: 'arraybuffer'});
}

export function getDownloadXlsReportUrl(id: any) {
  return axios.get(XLS_TEST_REPORT_URL + id, {responseType: 'blob'});
}

export function getDownloadXlsWebsiteReportUrl() {
  return axios.get(XLS_WEBSITES_REPORT_URL, {responseType: 'blob'});
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

export function getUserByOrg(departmentId: number, role: string) { 
  if (!departmentId || departmentId == -1) {
    return viewUser(role);
  }
  return decrypter(axios.get<Array<UserDbModel>>(USERS_BY_ORG_URL, {params: {role: role.toUpperCase(), departmentId}}));
}

export function viewUser(role?: string, status?: string, organizationId?: number, limit?: any, skip?: any) {
  const where: any = role && ['TESTER', 'ADMIN', 'SUPERADMIN'].indexOf(role.toUpperCase()) >= 0 ? {role} : {};
  if (status) {
    where.status = status;
  }
  if (organizationId) {
    where.organizationId = organizationId;
  }
  return decrypter(axios.get<Array<UserDbModel>>(USERS_URL, {params: {filter: {where, limit, skip, include: ['departments']}}}));
}

export function viewUserByRoleStatus(role?: string, status?: string, limit?: any, skip?: any) {
  const where: any = role && ['TESTER', 'ADMIN', 'SUPERADMIN'].indexOf(role.toUpperCase()) >= 0 ? {role} : {};
  if (status) {
    where.status = status;
  }
  return decrypter(axios.get<Array<UserDbModel>>(USERS_URL, {params: {filter: {where, limit, skip, include: ['departments']}}}));
}

export function viewMyTesters() {
  return decrypter(axios.get<Array<UserDbModel>>(VIEW_MY_TESTERS_URL));
}

export function getUser(id: string) {
  return decrypter(axios.get(USER_URL + id));
}

export function getUserMyself() {
  return decrypter(axios.get(USER_MYSELF_URL));
}

export function editUser(id: string, parichay: any, email: string, role: string, departmentIds: number[], status: string) {
  return decrypter(axios.post(PATCH_USER_URL + id, {parichay, email, role, departmentIds, status}));
}

export function activateUser(id: string) {
  return decrypter(axios.post(PATCH_USER_URL + id, {status: 'ACTIVE'}));
}

export function deleteUser(id: string) {
  return axios.post(DELETE_USER_URL + id);
}
